import { Box } from '@mui/material';
import { Variants } from 'common';
import React, { FC, useContext, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router';

import {
  GetSimulatorLocationsAdapter,
  IGetSimulatorLocationsHook,
} from 'application/modules/bookingWizard/useCases/hooks/useCaseGetSimulatorLocations';
import useCaseSelectExperience from 'application/modules/bookingWizard/useCases/hooks/useCaseSelectExperience';
import { WizardStep } from 'domain/entities/WizardStep';
import { useHookInjection } from 'domain/hooks';
import BookingCard from 'infrastructure/components/BookingCard';
import { StyledBookingCardContentSpacer } from 'infrastructure/components/BookingCard/style';
import NavigationBar from 'infrastructure/components/NavigationBar';
import PageLayout from 'infrastructure/components/PageLayout';
import { selectLocation } from 'infrastructure/redux/slices/bookingWizard.selector';
import { setExperienceType } from 'infrastructure/redux/slices/bookingWizard.slice';
import { useAppDispatch, useAppSelector } from 'infrastructure/redux/store/hooks';
import { TextCopyContext } from 'infrastructure/targets/web/contexts/TextCopyContext';
import LocationSelect from 'infrastructure/targets/web/modules/bookingWizard/components/LocationSelect';
import {
  getBookingImage,
  getLessonBookingImage,
} from 'infrastructure/targets/web/modules/common/imageUrlHelpers';
import useCaseChooseSessionLength from 'application/modules/bookingWizard/useCases/hooks/useCaseChooseSessionLength';
import { isAustralia } from 'infrastructure/targets/web/modules/common/helpers';

import { StyledCardsContainer } from './style';

interface ISelectExperienceStep {
  stepName: WizardStep;
}
const SelectExperienceStep: FC<ISelectExperienceStep> = ({ stepName }) => {
  const { getTextCopy } = useContext(TextCopyContext);
  const { prevStep, nextStep, preselectLocation, redirectToSelectedExperience } =
    useCaseSelectExperience(stepName);
  const { preselectPartySize, preselectReservationDate } = useCaseChooseSessionLength();
  const dispatch = useAppDispatch();
  const selectedLocationId = useAppSelector(selectLocation);
  const isGolf = [process.env.VITE_VARIANT].includes(Variants.FIVEIRON);

  const { search } = useLocation();
  const query = useMemo(() => new URLSearchParams(search), [search]);
  const getLocationsHook = useHookInjection<IGetSimulatorLocationsHook>(
    GetSimulatorLocationsAdapter,
  );
  const { inProgress: areLocationsFetching } = getLocationsHook;
  const experienceSlug = query?.get('experience');
  const locationSlug = query?.get('location');
  const partySizeSlug = query?.get('partySize');
  const startDateSlug = query?.get('startDate');

  useEffect(() => {
    if (!areLocationsFetching) {
      if (locationSlug) {
        preselectLocation(locationSlug);
      }
      if (partySizeSlug) {
        preselectPartySize(partySizeSlug);
      }
      if (startDateSlug) {
        preselectReservationDate(startDateSlug);
      }
      if (experienceSlug) {
        redirectToSelectedExperience(experienceSlug, partySizeSlug, startDateSlug);
      }
    }
  }, [experienceSlug, locationSlug, partySizeSlug, startDateSlug, areLocationsFetching]);

  const handleCardClick = (experienceType: string) => {
    dispatch(setExperienceType({ experienceType }));
    nextStep(experienceType);
  };

  return (
    <PageLayout pageTitle={'Book Your Next Visit'} shouldFadeIn>
      <NavigationBar backButtonCallback={prevStep}>
        <LocationSelect selectedSimLocationId={selectedLocationId} />
      </NavigationBar>
      <StyledCardsContainer>
        <BookingCard
          onCardButtonClick={handleCardClick}
          experienceType={isGolf ? 'simulator' : 'bowling'}
          title={getTextCopy('reserve')}
          content={
            <Box component="span">
              {!isAustralia() ? (
                <>
                  {getTextCopy('reserveDescription1')}
                  <StyledBookingCardContentSpacer />
                </>
              ) : null}
              {getTextCopy('reserveDescription2')}
              <StyledBookingCardContentSpacer />
              {getTextCopy('reserveDescription3')}
            </Box>
          }
          imageUrl={getBookingImage()}
        />
        {isGolf && (
          <BookingCard
            title="Book a Swing Evaluation or Lesson"
            experienceType="lesson"
            onCardButtonClick={handleCardClick}
            content={
              <Box component="span">
                Each lesson features state-of-the-art metrics and video analysis on TrackMan
                simulators.
                <StyledBookingCardContentSpacer />
                Unique mentoring based on your skill level and goals for the game.
                <StyledBookingCardContentSpacer />
                Pricing is based on the level of instructor.
              </Box>
            }
            imageUrl={getLessonBookingImage()}
          />
        )}
      </StyledCardsContainer>
    </PageLayout>
  );
};

export default SelectExperienceStep;
