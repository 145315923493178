export enum BookingWizardRoutes {
  BookingWizard = '/:stepName',
  BookingWizardCheckout = '/check-out/:stepName',
}

export enum AccountRoutes {
  CreateAccount = '/create-account',
  SignIn = '/sign-in',
  CheckYourEmail = '/check-your-email',
  VerifyUser = '/verify',
}
