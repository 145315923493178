import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useFormikContext } from 'formik';
import React, { FC } from 'react';

import { PreOrderFoodFormValues } from 'application/modules/bookingWizard/useCases/hooks/useCasePreOrderFood';
import FoodImagePlaceholder from 'infrastructure/targets/web/modules/bookingWizard/Steps/PreOrderFoodStep/components/FoodImagePlaceholder/FoodImagePlaceholder';
import FoodLineItemQuantitySelector from 'infrastructure/targets/web/modules/bookingWizard/Steps/PreOrderFoodStep/components/FoodSummaryCard/FoodLineItemQuantitySelector/FoodLineItemQuantitySelector';
import { formatToCurrency } from 'infrastructure/targets/web/modules/common/helpers';

export interface FoodLineItemValue {
  editMode?: boolean;
  name: string;
  title: string;
  productId: string;
  variantId: string;
  quantity: number;
  subtitle?: string;
  price?: number;
  imageUrl?: string;
}

const FoodLineItemQuantitySelectorOptions: { value: number | undefined; label: string }[] = [
  { value: undefined, label: 'Remove' },
  ...Array.from({ length: 100 }, (_, i): { value: number | undefined; label: string } => ({
    value: i + 1,
    label: `${i + 1}`,
  })),
];

const FoodLineItem: FC<FoodLineItemValue> = ({
  editMode,
  name,
  productId,
  variantId,
  title,
  quantity,
  subtitle,
  price,
  imageUrl,
}) => {
  const theme = useTheme();
  const { setFieldValue } = useFormikContext<PreOrderFoodFormValues>();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const imageSize = { width: isMobile ? '58px' : '64px', height: isMobile ? '58px' : '64px' };

  const onFoodLineItemQuantitySelectorChange = (value: unknown) => {
    if (!value) {
      setFieldValue(`order.${name}`, undefined);
    } else {
      setFieldValue(`order.${name}`, {
        name,
        productId,
        variantId,
        title,
        subtitle,
        price,
        imageUrl,
        quantity: value as number,
      });
    }
  };

  return (
    <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', justifyContent: 'space-between' }}>
      <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
        <Box sx={{ display: 'flex' }}>
          {imageUrl ? (
            <Box
              component={'img'}
              src={imageUrl}
              sx={{ ...imageSize, borderRadius: '8px', objectFit: 'cover' }}
            />
          ) : (
            <FoodImagePlaceholder variant="small" />
          )}
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography color="text.primary" variant="subtitle2" fontWeight={'bold'}>
            {title}
          </Typography>
          <Typography variant="subtitle2">{price ? formatToCurrency(price) : 'N/A'}</Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end',
        }}>
        {editMode && (
          <FoodLineItemQuantitySelector
            name={`order.${name}.quantity`}
            options={FoodLineItemQuantitySelectorOptions}
            onChange={onFoodLineItemQuantitySelectorChange}
            sx={{
              '& .MuiSelect-icon': {
                display: 'block',
              },
              width: isMobile ? '50' : '75px',
            }}
          />
        )}
        {!editMode && (
          <Typography color="text.primary" variant="subtitle2" fontWeight={'bold'}>
            {quantity}x
          </Typography>
        )}
      </Box>
    </Box>
  );
};
export default FoodLineItem;
