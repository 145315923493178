import { AppointmentTypes as ApiAppointmentTypes } from 'common';
import { format } from 'date-fns';
import enAU from 'date-fns/locale/en-AU';
import enUS from 'date-fns/locale/en-US';
import { injectable } from 'inversify';

import { AppointmentTypes, IMyBooking } from 'domain/entities/ManageBooking';
import { AsyncHookResult } from 'domain/hooks';
import { IManageBookingRepository } from 'domain/repositories/ManageBookingsRepository';
import { Entity } from 'domain/types';
import mboAPI from 'infrastructure/redux/adapters/mboAPI/mboApi';

const transformAppointment = (
  appointment: ApiAppointmentTypes.ClientAppointmentResponse,
  isUS: boolean,
): Entity<IMyBooking> => ({
  id: String(appointment.id),
  image: appointment.staffImage || '',
  title: appointment.locationName || '',
  rawTime: new Date(appointment.startDateTime),
  bookingDate: format(new Date(appointment.startDateTime), 'EEEE, P', {
    locale: isUS ? enUS : enAU,
  }),
  startTime: format(new Date(appointment.startDateTime), 'hh:mm a'),
  location: appointment.locationName || '',
  instructor: appointment.instructor || '',
  sessionLength: appointment.duration / 60 || 0,
  price: appointment.cost ? appointment.cost : null,
  pointsToEarn: appointment.pointsToEarn,
  bookingIds: appointment.cancelIds || [0],
  type: appointment.appointmentType as AppointmentTypes,
  locationId: appointment.internalLocId,
  hasArrived: appointment.hasArrived,
  checkInAvailableStartDate: new Date(appointment.checkInFromDateTime),
  checkInAvailableStartDateUTC: new Date(appointment.checkInFromDateTimeUTC),
});

@injectable()
class ManageBookingRepository implements IManageBookingRepository {
  useListMyBookings(isUS: boolean): AsyncHookResult<Entity<IMyBooking>[]> & {
    query(): void;
  } {
    const [query, result] = mboAPI.useLazyGetClientAppointmentsQuery();

    return {
      query,
      result:
        result?.data
          ?.map((appointment: ApiAppointmentTypes.ClientAppointmentResponse) =>
            transformAppointment(appointment, isUS),
          )
          .sort(
            (a: Entity<IMyBooking>, b: Entity<IMyBooking>) =>
              new Date(a.startTime).getTime() - new Date(b.startTime).getTime(),
          ) || [],
      error: result.error?.message as string | undefined,
      inProgress: result.isLoading,
    };
  }
  useListMyUpcomingBookings(isUS: boolean): AsyncHookResult<Entity<IMyBooking>[]> & {
    query(): void;
  } {
    const [query, { data, isLoading, isFetching, error }] =
      mboAPI.useLazyGetUpcomingAppointmentsQuery();

    return {
      query,
      result:
        data
          ?.map((appointment: ApiAppointmentTypes.ClientAppointmentResponse) =>
            transformAppointment(appointment, isUS),
          )
          .sort(
            (a: Entity<IMyBooking>, b: Entity<IMyBooking>) =>
              new Date(a.startTime).getTime() - new Date(b.startTime).getTime(),
          ) || [],
      error: error as string | undefined,
      inProgress: isLoading || isFetching,
    };
  }
}

export default ManageBookingRepository;
