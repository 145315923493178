import { Box, Typography } from '@mui/material';
import React, { FC, useEffect, useRef, useState } from 'react';

import useCaseSelectInstructor from 'application/modules/bookingWizard/useCases/hooks/useCaseSelectInstructor';
import { WizardStep } from 'domain/entities/WizardStep';
import HorizontalCard, { HorizontalCardType } from 'infrastructure/components/HorizontalCard';
import HorizontalCardSkeleton from 'infrastructure/components/HorizontalCard/HorizontalCardSkeleton';
import NavigationBar from 'infrastructure/components/NavigationBar';
import PageLayout from 'infrastructure/components/PageLayout';
import { selectLocation } from 'infrastructure/redux/slices/bookingWizard.selector';
import { setLessonType, setReservationDate } from 'infrastructure/redux/slices/bookingWizard.slice';
import { useAppDispatch, useAppSelector } from 'infrastructure/redux/store/hooks';
import LocationSelect from 'infrastructure/targets/web/modules/bookingWizard/components/LocationSelect';
import { getLessonBookingImage } from 'infrastructure/targets/web/modules/common/imageUrlHelpers';

import InstructorsFilterSelect from './components/InstructorsFilterSelect';
import InstructorsList from './components/InstructorsList';
import SearchBar from './components/SearchBar';
import { StyledCardsContainer, StyledContentContainer, StyledTitleContainer } from './style';

interface ISelectInstructorStep {
  stepName: WizardStep;
}

const SelectInstructorStep: FC<ISelectInstructorStep> = ({ stepName }) => {
  const dispatch = useAppDispatch();
  const today = useRef(new Date());
  const selectedLocationId = useAppSelector(selectLocation);

  const {
    firstTimeLessons,
    result: instructorsList,
    inProgress: instructorsLoading,
    nextStep,
    prevStep,
    firstTimeLessonsLoading,
  } = useCaseSelectInstructor(stepName);

  const [instructorsToShow, setInstructorsToShow] = useState(instructorsList);

  const handleCardClick = (value: number) => {
    nextStep(value);
  };

  const handleSelectFirstTimeLesson = () => {
    nextStep(null, true);
  };

  const handleInstructorsFilter = (instructorTypes: string[]) => {
    const filteredInstructors = instructorsList?.filter((instructor) =>
      instructorTypes.includes(instructor.staffLevel),
    );
    setInstructorsToShow(filteredInstructors);
  };

  const getFirstTimeLessonChipLabel = (): string => {
    if (firstTimeLessons) {
      const firstTimeLesson = firstTimeLessons[0];
      const { defaultTimeLength } = firstTimeLesson;
      return `${defaultTimeLength} Minute Lesson`;
    }
    return '';
  };

  const getFirstTimeLessonContent = (): string => {
    if (firstTimeLessons) {
      const firstTimeLesson = firstTimeLessons[0];
      const { defaultTimeLength } = firstTimeLesson;
      return `The Swing Evaluation is a ${defaultTimeLength}-minute session designed to discuss your golf history and any frustrations you are currently having with your game. Then, together, we’ll work to establish measurable goals. We will introduce you to the TrackMan technology and evaluate your swing based on seeing you hit multiple different shots, before identifying the root cause of your inconsistencies. Most importantly, we will develop a custom improvement plan to get you on the path towards achieving your golfing dreams!`;
    }
    return '';
  };

  useEffect(() => {
    setInstructorsToShow(instructorsList);
  }, [instructorsList]);

  const handleSearch = (lessonType: string | undefined, date: Date) => {
    dispatch(setReservationDate({ reservationDate: date }));
    dispatch(setLessonType({ lessonType }));
    if (lessonType) {
      const filteredInstructors = instructorsList?.filter((instructor) =>
        instructor.services.some(
          (service) => Number(service.defaultTimeLength) === Number(lessonType),
        ),
      );
      setInstructorsToShow(filteredInstructors);
    }
  };

  useEffect(() => {
    dispatch(setReservationDate({ reservationDate: `${today.current}` }));
  }, []);
  return (
    <PageLayout pageTitle={'Select Your Instructor'} shouldFadeIn>
      <NavigationBar backButtonCallback={prevStep}>
        <LocationSelect selectedSimLocationId={selectedLocationId} />
      </NavigationBar>
      <StyledContentContainer>
        <SearchBar onSearch={handleSearch} />
        <StyledTitleContainer>
          <Typography variant="h3">Five Iron Instructors</Typography>
          <InstructorsFilterSelect onChange={handleInstructorsFilter} />
        </StyledTitleContainer>

        <StyledCardsContainer>
          {firstTimeLessonsLoading ? (
            <Box sx={{ width: '100%', display: 'flex', gap: 4, flexDirection: 'column' }}>
              <HorizontalCardSkeleton />
            </Box>
          ) : firstTimeLessons?.length ? (
            <HorizontalCard
              variant={HorizontalCardType.Large}
              title={'Swing Evaluation'}
              noBottomMargin
              chipLabel={getFirstTimeLessonChipLabel()}
              handleCTA={() => handleSelectFirstTimeLesson()}
              content={getFirstTimeLessonContent()}
              imageUrl={getLessonBookingImage()}
            />
          ) : (
            ''
          )}
          <InstructorsList
            loading={instructorsLoading}
            onCardClick={handleCardClick}
            instructorsList={instructorsToShow}
          />
        </StyledCardsContainer>
      </StyledContentContainer>
    </PageLayout>
  );
};

export default SelectInstructorStep;
