import { FeatureCarouselItem } from './FeatureCarousel';

export enum RemoteConfigKey {
  should_force_app_update = 'should_force_app_update',
  carousel_items = 'carousel_items',
  enable_qr_code_checkin = 'enable_qr_code_checkin',
}

export interface RemoteConfigValues {
  [RemoteConfigKey.should_force_app_update]: boolean;
  [RemoteConfigKey.carousel_items]: FeatureCarouselItem[];
  [RemoteConfigKey.enable_qr_code_checkin]: Record<string, boolean>;
}
