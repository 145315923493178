import { BrowserRouter, Routes } from 'react-router-dom';
import React, { Fragment } from 'react';
import { useAllInjections } from 'inversify-react';

import IWebModule, { WebModule } from 'infrastructure/targets/web/types/IWebModule';
import ScrollTop from 'infrastructure/targets/web/common/ScrollTop';

const Router: React.FC = () => {
  const modules = useAllInjections<IWebModule>(WebModule);

  return (
    <BrowserRouter>
      <ScrollTop />
      <Routes>
        {modules.map((m, idx) => (
          <Fragment key={idx}>{m.getRoutes()}</Fragment>
        ))}
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
