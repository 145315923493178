import { createSelector } from '@reduxjs/toolkit';
import { UserSummary } from 'common/infrastructure/modules/auth/interfaces/UserTypes';

import { RootState } from 'infrastructure/redux/store';

import { UserState } from './user.slice';

export const selectSelf = (storeState: RootState): RootState => storeState;

export const selectUserState = createSelector(
  selectSelf,
  (state: RootState): UserState => state.user,
);

export const selectUserSummary = createSelector(
  selectUserState,
  (state: RootState): UserSummary => state.summary ?? {},
);

export const selectIsUserSummaryFetching = createSelector(
  selectUserState,
  (state: RootState): boolean => state.isSummaryFetching,
);
