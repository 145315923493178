import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserSummary } from 'common/dist/infrastructure/modules/auth/interfaces/UserTypes';

export interface UserState {
  summary?: UserSummary;
  isSummaryFetching?: boolean;
}

const userSlice = createSlice({
  name: 'user',
  initialState: {},
  reducers: {
    setUserSummary: (state: UserState, action: PayloadAction<UserSummary | undefined>) => ({
      ...state,
      summary: action.payload,
    }),
    setIsUserSummaryFetching: (state: UserState, action: PayloadAction<boolean>) => ({
      ...state,
      isSummaryFetching: action.payload,
    }),
    clearUserData: () => ({ summary: undefined }),
  },
});

export const { setUserSummary, setIsUserSummaryFetching, clearUserData } = userSlice.actions;
export default userSlice.reducer;
